/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f8f8;
    margin: 0;
    padding: 0;
  }
  
  /* Market Container */
  .market-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .market-container:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Banner Image */
  .banner-container {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .market-banner-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
  }
  
  .market-banner-image:hover {
    opacity: 0.8;
  }
  
  /* Market Title */
  .market-title {
    font-size: 36px;
    color: #396d4f;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  /* Market Subtitle */
  .market-subtitle {
    font-size: 24px;
    color: #7b0303;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Market Description */
  .market-description {
    font-size: 18px;
    line-height: 1.8;
    color: #333;
    margin-bottom: 30px;
    padding: 0 15px;
    text-align: justify;
  }
  
  /* Footer Description */
  .footer-description {
    font-size: 16px;
    color: #333;
    line-height: 1.7;
    margin-bottom: 30px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  /* Terms and Conditions */
  .terms-conditions {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 30px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  /* Social Media Links */
  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .social-link {
    text-decoration: none;
    color: #fff;
    background-color: #396d4f;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .social-link:hover {
    background-color: #7b0303;
    transform: scale(1.1);
  }
  
  /* Market Details */
  .market-details {
    font-size: 16px;
    color: #555;
    margin-top: 40px;
    padding: 0 15px;
  }
  
  .market-details p {
    margin: 10px 0;
  }
  
  .market-details strong {
    font-weight: bold;
    color: #396d4f;
  }
  
  /* Categories and Subcategories */
  .categories, .subcategories {
    margin-top: 20px;
    padding-left: 20px;
  }
  
  .categories ul, .subcategories ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .categories li, .subcategories li {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    padding: 5px 0;
  }
  
  .categories li::before, .subcategories li::before {
   
    color: #396d4f;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .categories h3, .subcategories h3 {
    font-size: 20px;
    color: #396d4f;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .categories-subcategories-row {
    display: flex;
    justify-content: space-between; /* Distribute space between sections */
    gap: 20px; /* Space between categories and subcategories */
  }
  
  .categories, .subcategories {
    flex: 1; /* Make both sections take up equal space */
  }
  
  ul {
    padding-left: 20px;
  }
  
 
  /* Market Dates */
  .market-dates {
    margin-top: 40px;
    padding: 0 15px;
  }
  
  .market-dates h3 {
    font-size: 20px;
    color: #396d4f;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .market-dates ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .market-dates li {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
  }
  
  .market-dates li span {
    color: #396d4f;
    font-weight: bold;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .market-container {
      padding: 15px;
    }
  
    .market-title {
      font-size: 28px;
    }
  
    .market-subtitle {
      font-size: 20px;
    }
  
    .market-description {
      font-size: 16px;
    }
  
    .footer-description, .terms-conditions {
      font-size: 14px;
    }
  
    .social-links {
      flex-direction: column;
      align-items: center;
    }
  
    .social-link {
      margin-bottom: 10px;
      width: 100%;
      text-align: center;
    }
  
    .market-dates ul {
      padding-left: 20px;
    }
  
    .categories ul, .subcategories ul {
      padding-left: 20px;
    }
  }
  
  .market-sections {
    margin-top: 30px;
  }
  
  .section-columns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .section-column {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .section-column img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .section-column h4 {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .section-description {
    margin-top: 10px;
  }
  /* Market Card Grid Layout */
.market-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.market-card {
  width: 100%;
  height: 300px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.market-card:hover {
  transform: scale(1.05);
}

.card-image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.card-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 900px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-close-btn {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-header h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body h1,
.modal-body h2 {
  font-size: 20px;
}

.modal-body p {
  margin-top: 10px;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.social-link {
  text-decoration: none;
  font-size: 18px;
  color: #0073e6;
}

.social-link:hover {
  text-decoration: underline;
}
/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 900px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative; /* Keep header and footer fixed */
  max-height: 80vh;  /* Limit the height of the modal */
  overflow: hidden;  /* Hide overflow for the entire modal */
}

/* Modal Header Styling */
.modal-header h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-close-btn {
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Modal Body - Make it scrollable */
.modal-body {
  max-height: 60vh;  /* Max height for the modal body */
  overflow-y: auto;  /* Allow vertical scrolling */
  padding-right: 15px; /* Add padding for scroll bar */
}

/* Modal Footer */
.modal-footer {
  padding-top: 10px;
  text-align: center;
  font-size: 14px;
}

/* Social Links */
.social-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.social-link {
  text-decoration: none;
  font-size: 18px;
  color: #f3f5f7;
}

.social-link:hover {
  text-decoration: underline;
}
/* Close Button Styling */
.modal-close-btn {
  background-color: red; /* Red background */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 15px; /* Add some padding */
  font-size: 18px; /* Adjust the font size */
  cursor: pointer; /* Make the button clickable */
  border-radius: 5px; /* Rounded corners */
  position: absolute; /* Fix the position */
  top: 15px; /* Position it near the top of the modal */
  right: 15px; /* Align it to the right */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Change background color on hover */
.modal-close-btn:hover {
  background-color: darkred; /* Darker red when hovered */
}/* Styling for the Markets Header */
.markets-header {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.markets-header h2 {
  font-size: 32px;
  color: #396d4f; /* You can adjust the color */
  font-weight: bold;
}

.markets-header p {
  font-size: 18px;
  color: #555; /* Lighter text for the description */
  font-weight: normal;
}

.green-heading {
  font-size: 20px;
    color: #396d4f;
    font-weight: bold;
    margin-bottom: 10px;
}




















.market-dates {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.green-heading {
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  color: #4caf50;
  font-weight: bold;
  margin-bottom: 8px;
}

.market-date-wrapper {
  display: flex;
  flex-wrap: wrap;  /* Allow wrapping if there are too many items */
  gap: 12px;  /* Space between the cards */
}

.market-date-card {
  background-color: #e0f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  width: 120px;  /* Card width */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.market-date-item {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

.no-dates-message {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #f44336;
  font-weight: normal;
  padding: 6px;
}



.subcategories {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
}

.green-heading {
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  color: #4caf50;
  font-weight: bold;
  margin-bottom: 8px;
}

.category-wrapper {
  display: flex;
  flex-wrap: wrap;  /* Allow wrapping if there are too many items */
  gap: 12px;  /* Space between the cards */
}

.category-card {
  background-color: #e0f7fa;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  width: 120px;  /* Card width */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.category-name {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

.no-categories-message {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  color: #f44336;
  font-weight: normal;
  padding: 6px;
}
