/* MeetOurVendor CSS */

/* Modal Styles */
.custom-modal .modal-dialog {
    max-width: 50%; /* Increase the width of the modal */
    margin: 30px auto; /* Center the modal */
  }
  
  .custom-modal .modal-content {
    border-radius: 10px; /* Optional: rounded corners */
  }
  
  .custom-modal .modal-header {
    border-bottom: none; /* Remove default border */
  }
  
  .custom-modal .modal-footer {
    border-top: none; /* Remove default border */
  }
  
  .vendor-image {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure image covers the area without stretching */
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure image covers the area without stretching */
    border-radius: 8px; /* Optional: rounded corners */
  }
  
  /* Adjust modal body content to ensure proper spacing */
  .modal-body {
    padding: 20px; /* Add padding inside the modal body */
  }
  
  .modal-body p {
    margin-bottom: 10px; /* Add margin between paragraphs */
  }
  
